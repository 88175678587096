import React from 'react';
import { Grid } from '@mui/material';
import OrderCard from './orderCard';
import H5Typography from './H5Typography';

const style = {
  px: 2,
  borderStyle: 'solid',
  borderColor: '#0000001f',
  borderTopWidth: 0,
  borderRightWidth: 'thin',
  borderLeftWidth: 0,
};
function OrderColumn({ title, orders }) {
  return (
    <Grid item md={3} lg={3} sm={3} sx={style}>
      <H5Typography title={title} color="#3C3C3C" />
      {orders.map((order) => (
        // eslint-disable-next-line react/jsx-key
        <OrderCard order={order} />
      ))}
    </Grid>
  );
}
export default OrderColumn;
