import React from 'react';
import { Typography } from '@mui/material';

function H5Typography({ title, color }) {
  return (
    <Typography
      sx={{
        color,
        mt: 3,
        mb: 4,
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: '24px',
        fontSize: '20px',
      }}
    >
      {title}
    </Typography>
  );
}
export default H5Typography;
