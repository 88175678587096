import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#00CEC9',
    height: '60px',
  },
  icon: {
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    flex: '50%',
  },
}));
