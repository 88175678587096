import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 3,
    fontWeight: 500,
    letterSpacing: '0.05em',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: (props) => props.height,
    width: (props) => props.width,
    color: (props) => props.color,
    fontSize: (props) => `${props.fontSize}vw`,
    backgroundColor: (props) => props.bgcolor,
    marginTop: (props) => theme.spacing(props.marginTop || 0),
    marginLeft: (props) => theme.spacing(props.marginLeft || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: (props) => props.fontSizeMd - 2,
      paddingLeft: theme.spacing(1.7),
      paddingRight: theme.spacing(1.7),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: (props) => props.fontSizeMd - 3,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: (props) => props.fontSizeMd,
      paddingLeft: theme.spacing(2.4),
      paddingRight: theme.spacing(2.4),
    },
  },
}));

export default function MuiButton({
  children, fullWidth, href, loading, ...props
}) {
  const { button } = useStyles(props);
  return (
    <Button
      disableElevation
      className={button}
      href={href}
      fullWidth={fullWidth}
      variant={!props.transparent ? 'contained' : null}
      color="primary"
      {...props}
    >
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
}

MuiButton.defaultProps = {
  loading: false,
};
