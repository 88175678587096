import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import * as types from './store/types';
import * as style from './style';
import OrderColumn from '../layout/OrderColumn';
import MUIBackdrop from '../layout/BackDrop';
import * as constants from '../../utils/constants';

function CSRDashboard() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.activeUser);
  const incomingOrders = useSelector((state) => state.dashboard.incoming_orders);
  const pendingOrders = useSelector((state) => state.dashboard.pending_orders);
  const preparingOrders = useSelector((state) => state.dashboard.preparing_orders);
  const deliveringOrders = useSelector((state) => state.dashboard.delivering_orders);
  const pendingReviewOrders = useSelector((state) => state.dashboard.pending_review_orders);
  const ordersLoading = useSelector((state) => state.dashboard.loading);

  useEffect(() => {
    if (currentUser && !ordersLoading) {
      dispatch({ type: types.GET_ORDERS });
    }
  }, [currentUser]);

  if (ordersLoading) return <MUIBackdrop open={ordersLoading} />;
  return (
    <div style={style.root}>
      {!ordersLoading && (
        <Grid container spacing={2} columns={15} sx={style.parentGrid}>
          <OrderColumn title={constants.INCOMING_ORDERS} orders={incomingOrders} />
          <OrderColumn title={constants.PENDING_ORDERS} orders={pendingOrders} />
          <OrderColumn title={constants.PREPARING_ORDERS} orders={preparingOrders} />
          <OrderColumn title={constants.DELIVERING_ORDERS} orders={deliveringOrders} />
          <OrderColumn title={constants.REVIEW_PENDING_ORDERS} orders={pendingReviewOrders} />
        </Grid>
      )}
    </div>
  );
}
export default CSRDashboard;
