export const addressDiv = {
  float: 'left',
  marginTop: '5px',
  width: '45%',
};

export const ratingDiv = {
  float: 'right',
  textAlign: 'right',
  display: 'block',
  marginTop: '5px',
};

export const mainDiv = {
  borderRadius: 1,
  borderLeft: 3,
  boxShadow: 4,
  marginBottom: 2,
  display: 'block',
  overflow: 'hidden',
  p: 1.5,
  cursor: 'pointer',
};

export const address = {
  fontWeight: '600',
  fontSize: '15px',
};

export const ratingComponent = {
  fontSize: '15px',
  height: '22px',
  alignItems: 'center',
};

export const phoneNumberDiv = {
  display: 'inline-flex',
  alignItems: 'center',
  height: '22px',
};

export const orderItemDiv = {
  display: 'flex',
  alignItems: 'flex-start',
};

export const CallIcon = {
  color: '#00CEC9',
  fontSize: '15px',
};

export const phoneNumber = {
  color: '#00CEC9',
  marginLeft: '5px',
};
