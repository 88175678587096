import { takeLatest, put, call } from 'redux-saga/effects';

import * as types from './types';
import FetchOrders from '../api';

// eslint-disable-next-line no-unused-vars
function* handleGetOrders(action) {
  yield put({ type: types.LOADING_ORDERS });
  try {
    const response = yield call(FetchOrders);
    yield put({ type: types.FETCH_ORDERS_SUCCESSFUL, payload: response.data });
  } catch (e) {
    yield put({ type: types.FETCH_ORDERS_FAILED });
  }
}

export default function* DashboardSaga() {
  yield takeLatest(types.GET_ORDERS, handleGetOrders);
}
