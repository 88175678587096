export const root = {
  overflow: 'scroll',
  height: '100vh',
};

export const loadingDiv = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

export const parentGrid = {
  marginTop: 0,
  marginLeft: 0,
  width: '100%',
  height: '100%',
};
