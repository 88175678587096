import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../components/layout/header';
import Backdrop from '../components/layout/BackDrop';
import routesConfig from './routesConfig';

function CustomRoute({ component, ...rest }) {
  const loadingUser = useSelector((state) => state.user.loadingUser);
  const currentUser = useSelector((state) => state.user.activeUser);
  const Component = component;

  if (loadingUser && !currentUser) return <Backdrop open={loadingUser} />;
  return (
    <Route
      {...rest}
      component={(props) => {
        if (!loadingUser && !currentUser
            && props.history.location.pathname !== routesConfig.login) {
          props.history.push(routesConfig.login);
          return <></>;
        }
        return (
          <div style={{ overflow: 'scroll', height: '100vh' }}>
            <Header />
            <Component {...props} />
          </div>
        );
      }}
    />
  );
}
export default CustomRoute;
