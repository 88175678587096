import axios from 'axios';

export const updateUserToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getBorderColor = (order, setBorderColor) => {
  if (order && order.delivery_deadline) {
    const dateTime = new Date(Date.now());
    const promisedDeliveryDeadline = new Date(order.delivery_deadline);
    const diffInMin = Math.abs(promisedDeliveryDeadline - dateTime) / (1000 * 60);
    if (diffInMin < 0) {
      setBorderColor('#3C3C3C');
    } else if (diffInMin < 5) {
      setBorderColor('#FF5757');
    } else if (diffInMin < 10) {
      setBorderColor('#AAAAAA');
    } else {
      setBorderColor('#3C3C3C');
    }
  }
};
