import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#007BFE',
    },
    secondary: {
      main: '#ffffff',
    },
    tertiary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Mark OT',
    htmlFontSize: 17,
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: 'inherit',
        '&:focus': {
          outline: 0,
        },
      },
    },
    MuiTableBody: {
      root: {
        '& tr:nth-of-type(odd)': {
          backgroundColor: 'rgb(241, 241, 243)',
        },
      },
    },
  },
});
