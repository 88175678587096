import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import TextField from '@mui/material/TextField';
import auth from '../../services/firebase';
import MuiButton from '../layout/button';
import { types } from './store/actions';
import useStyles from './style';
import Dialog from '../layout/dialog';
import routesConfig from '../../routes/routesConfig';
import * as constants from '../../utils/constants';

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.activeUser);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [OTP, setOTP] = useState();
  const [sendingOTP, setSendingOTP] = useState(false);
  const onlyCountries = ['pk'];

  const handlePhoneNumberChange = (number) => {
    setPhoneNumber(number);
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
      'size': 'invisible',
    }, auth);
  };

  const isValidNumber = (number) => /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{2})[-]?([0-9]{7})$/.test(number);

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValidNumber(phoneNumber.replace(/\s+/g, ''))) {
      generateRecaptcha();
      setLoading(true);
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setSendingOTP(true);
        }).catch((otpError) => {
          setError(otpError.message);
          setLoading(false);
          setSendingOTP(false);
          // eslint-disable-next-line no-underscore-dangle
          appVerifier._reset();
        });
    } else {
      setError(constants.INVALID_NUMBER);
    }
  };

  const onOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const isValidOTP = () => OTP.length === 6;

  const verifyOTP = (e) => {
    e.preventDefault();
    if (isValidOTP()) {
      setLoading(true);
      window.confirmationResult.confirm(OTP).then((result) => {
        dispatch({ type: types.LOGIN_SUCCESSFUL, payload: result.user });
        setLoading(false);
        props.history.push(routesConfig.csrDashboard);
        // eslint-disable-next-line no-shadow
      }).catch((error) => {
        setError(error.error.message);
        setLoading(false);
      });
    } else {
      setError(constants.INVALID_OTP_MESSAGE);
    }
  };
  const handleClose = () => {
    setError(undefined);
  };

  const showErrorDialog = () => (
    <Dialog
      open={Boolean(error)}
      onClose={handleClose}
      onClick={handleClose}
      label={constants.OK}
      title={constants.ERROR}
      style={classes.dialog_root}
    >
      <Typography className={classes.message}>{error}</Typography>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      {currentUser && props.history.push(routesConfig.csrDashboard)}
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Box pt={20}>
            <Typography className={classes.title} gutterBottom>
              Sign In.
            </Typography>
            <form className={classes.form} onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <MuiPhoneNumber
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    defaultCountry="pk"
                    label={constants.PHONE_NUMBER}
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    className={classes.field}
                    onlyCountries={onlyCountries}
                  />
                </Grid>
                {!sendingOTP && (
                <Grid item xs={12} sm={10}>
                  <MuiButton
                    marginTop={3}
                    fontSize={1.2}
                    fontSizeMd={14}
                    fullWidth
                    height="50px"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    sx={{ backgroundColor: '#00CEC9' }}
                  >
                    {constants.SIGN_IN}
                  </MuiButton>
                </Grid>
                )}
              </Grid>
            </form>
            {sendingOTP && (
            <form className={classes.form} onSubmit={verifyOTP}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    className={classes.field}
                    name="otp"
                    fullWidth
                    id="otp"
                    label={constants.OTP}
                    onChange={onOTPChange}
                    variant="outlined"
                  />
                  <MuiButton
                    marginTop={3}
                    fontSize={1.2}
                    fontSizeMd={14}
                    fullWidth
                    height="50px"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    {constants.VERIFY_OTP}
                  </MuiButton>
                </Grid>
              </Grid>
            </form>
            )}
          </Box>
        </Grid>
      </Grid>
      {error && showErrorDialog()}
      <div id="recaptcha" />
    </div>
  );
}

export default withRouter(Login);
