import React, { useEffect, useState } from 'react';
import {
  Typography, Box, Link,
} from '@mui/material';

import CallIcon from '@mui/icons-material/Call';
import * as style from './style';
import SubscribedIcon from '../../../assets/subscribed.png';
import UnSubscribedIcon from '../../../assets/not_subscribed.png';
import { getBorderColor } from '../../../utils/utils';
import * as constants from '../../../utils/constants';

function OrderCard({ order }) {
  const [borderColor, setBorderColor] = useState('#2BD870');

  useEffect(() => {
    const interval = setInterval(() => getBorderColor(order, setBorderColor), 5000);
    getBorderColor(order, setBorderColor);
    return () => clearInterval(interval);
  }, []);

  const onOrderCardClick = () => {
    window.open(`${process.env.REACT_APP_BACKEND_HOST}${process.env.REACT_APP_DJANGO_ORDER_BACKEND}${order.id}/change`,
      'blank');
  };

  return (
    <Box
      sx={style.mainDiv}
      style={{ borderColor }}
      onClick={onOrderCardClick}
    >
      {order.lines.map((item) => (
        // eslint-disable-next-line no-nested-ternary
        item.media_url
          ? (item.description === 'image' ? (
            <Link sx={{ display: 'block' }} href={item.media_url}>
              {item.description}
              {item.media_url_description && (
              <>
                {' '}
                -
                {' '}
                {item.media_url_description}
              </>
              )}
            </Link>
          ) : (// eslint-disable-next-line jsx-a11y/media-has-caption
            <audio src={item.media_url} controls style={{ width: '100%' }} />)
          )
          : (
            <Typography>
              {item.description}
            </Typography>
          )
      ))}
      <div style={style.addressDiv}>
        <Typography noWrap sx={style.address}>
          {order.delivery_address.address}
        </Typography>
        <Typography>
          Rs
          {' '}
          {order.delivery_charges}
        </Typography>
        <Typography>
          {order.promised_delivery_deadline_minutes}
          {' '}
          minutes
        </Typography>
      </div>
      <div style={style.ratingDiv}>
        <Typography sx={style.address}>
          {order.delivery_address.area_name}
        </Typography>
        <div style={{ height: '22px' }}>
          <img
            src={order.subscribed ? SubscribedIcon : UnSubscribedIcon}
            width="15px"
            alt={order.subscribed ? constants.SUBSCRIBED : constants.NOT_SUBSCRIBED}
            style={{ marginTop: '3px' }}
          />
        </div>
        <div style={style.phoneNumberDiv}>
          <CallIcon sx={style.CallIcon} />
          <Typography sx={style.phoneNumber}>
            {order.customer.phone_number}
          </Typography>
        </div>
      </div>
    </Box>
  );
}
export default OrderCard;
