import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Redirect,
  Route, Switch,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import LoginPage from '../components/login';
import CSRDashboard from '../components/CSRDashboard';
import { types } from '../components/login/store/actions';
import auth from '../services/firebase';
import { updateUserToken } from '../utils/utils';
import CustomRoute from './CustomRoute';
import routesConfig from './routesConfig';

export default function RoutesV2() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.activeUser);

  useEffect(() => {
    if (currentUser === null) {
      dispatch({ type: types.LOADING_USER, payload: true });
    } else {
      dispatch({ type: types.LOADING_USER, payload: false });
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        updateUserToken(user.stsTokenManager.accessToken);
        dispatch({ type: types.SET_CURRENT_USER, payload: user });
      } else {
        dispatch({ type: types.NO_ACTIVE_USER });
      }
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to={routesConfig.csrDashboard} />
        </Route>
        <CustomRoute path={routesConfig.login} exact component={LoginPage} />
        <CustomRoute path={routesConfig.csrDashboard} exact component={CSRDashboard} />
      </Switch>
    </Router>
  );
}
