import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiCircularProgress from '../muiCircularProgress';

import {
  DialogTitle,
  DialogContent,
  DialogueActions,
} from './style';

const CustomDialog = ({
  open,
  onClose,
  onClick,
  title,
  label,
  loading,
  children,
  maxWidth,
  href,
  disabled,
  style = true,
}) => (
  <Dialog
    onClose={onClose}
    open={open}
    maxWidth={maxWidth}
    fullWidth={style}
    className={style}
    aria-labelledby="customized-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {loading && (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      ml="-20px"
    >
      <MuiCircularProgress />
    </Box>
    )}
    <DialogTitle id="customized-dialog-title" onClose={onClose}>
      <strong>{title}</strong>
    </DialogTitle>
    <DialogContent id="alert-dialog-description" dividers>{children}</DialogContent>
    {label && (
    <DialogueActions>
      <Button autoFocus onClick={onClick} href={href} color="primary" disabled={disabled}>
        <strong>{label}</strong>
      </Button>
    </DialogueActions>
    )}
  </Dialog>
);
export default CustomDialog;
