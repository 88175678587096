import { combineReducers } from 'redux';

import userReducer from '../components/login/store/reducer';
import dashboardReducer from '../components/CSRDashboard/store/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
