import * as types from './types';

export const setLoginSuccess = (userData) => ({
  type: types.LOGIN_SUCCESSFUL,
  payload: userData,
});

export const setLoginError = (error) => ({
  type: types.LOGIN_FAILED,
  payload: error,
});

export const setLoading = () => ({
  type: types.LOADING_LOGIN,
});

export const resetError = () => ({
  type: types.ERROR_FIX,
});
