import * as types from './types';

const initialState = {
  loading: false,
  incoming_orders: [],
  pending_orders: [],
  preparing_orders: [],
  delivering_orders: [],
  pending_review_orders: [],
  error: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_SUCCESSFUL:
      return {
        ...state,
        incoming_orders: action.payload.incoming_orders,
        pending_orders: action.payload.pending_orders,
        preparing_orders: action.payload.preparing_orders,
        delivering_orders: action.payload.delivering_orders,
        pending_review_orders: action.payload.pending_review_orders,
        loading: false,
      };
    case types.LOADING_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_ORDERS_FAILED:
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch orders',
      };
    default:
      return state;
  }
};

export default dashboardReducer;
