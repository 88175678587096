import { takeLatest, put } from 'redux-saga/effects';

import { actions, types } from './actions';

// eslint-disable-next-line no-unused-vars
function* handleRequestOTP(action) {
  yield put(actions.setLoading());
}

export default function* LoginSaga() {
  yield takeLatest(types.REQUEST_OTP, handleRequestOTP);
}
