import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import axios from 'axios';
import theme from '../../theme';
import Routes from '../../routes';
import store from '../../store';
import auth from '../../services/firebase';
import NotAuthorizedMessageBox from '../layout/NotAuthorizedMessageBox';
import routesConfig from '../../routes/routesConfig';

function App() {
  const [open, setOpen] = useState(true);
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(true);

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if ([401, 403].includes(err.response.status)) {
        setIsAuthorizedUser(false);
        return;
      }
      return Promise.reject(err);
    },
  );

  const handleOnClick = () => {
    auth.signOut();
    window.location.href = routesConfig.login;
    setOpen(false);
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {isAuthorizedUser && <Routes />}
        {!isAuthorizedUser && <NotAuthorizedMessageBox open={open} handleOnClick={handleOnClick} />}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
