export const REQUEST_OTP = 'REQUEST_OTP';
export const UNABLE_TO_SEND_OTP = 'UNABLE_TO_SEND_OTP';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOADING_LOGIN = 'LOADING_LOGIN';
export const ERROR_FIX = 'ERROR_FIX';

export const LOADING_USER = 'LOADING_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const NO_ACTIVE_USER = 'NO_ACTIVE_USER';
