import React from 'react';
import { Box } from '@mui/material';

import FataFatLogo from '../../../assets/Fatafat Logo.png';
import useStyles from './style';

function Header() {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Box className={classes.icon}>
        <img width="100px" src={FataFatLogo} alt="FataFat Logo" />
      </Box>
    </div>
  );
}
export default Header;
