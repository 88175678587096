import { types } from './actions';

const initialState = {
  loading: false,
  activeUser: null,
  loadingUser: true,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        activeUser: action.payload,
      };
    case types.LOADING_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case types.UNABLE_TO_SEND_OTP:
      return {
        ...state,
        error: 'Unable to Send OTP kindly Try Again',
        loading: false,
      };
    case types.LOADING_USER:
      return {
        ...state,
        loadingUser: action.payload,
      };
    case types.SET_CURRENT_USER:
      return {

        ...state,
        activeUser: action.payload,
        loadingUser: false,
      };
    case types.NO_ACTIVE_USER:
      return {
        ...state,
        loadingUser: false,
      };
    case types.ERROR_FIX:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
};

export default userReducer;
