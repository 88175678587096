import React from 'react';
import { Backdrop, Typography } from '@mui/material';
import { NOT_AUTHORIZED_MESSAGE } from '../../utils/constants';

function NotAuthorizedMessageBox({ open, handleOnClick }) {
  return (
    <Backdrop open={open} onClick={handleOnClick}>
      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
        {NOT_AUTHORIZED_MESSAGE}
      </Typography>
    </Backdrop>
  );
}
export default NotAuthorizedMessageBox;
