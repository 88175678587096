import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

function MuiCircularProgress({ size }) {
  return (
    <CircularProgress sx={{ color: '#000' }} size={size} />
  );
}

export default MuiCircularProgress;
