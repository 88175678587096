export const INCOMING_ORDERS = 'Incoming Orders';
export const PENDING_ORDERS = 'Pending Orders';
export const PREPARING_ORDERS = 'Preparing Orders';
export const DELIVERING_ORDERS = 'Delivering Orders';
export const REVIEW_PENDING_ORDERS = 'Review Pending Orders';
export const OK = 'OK';
export const ERROR = 'ERROR';
export const PHONE_NUMBER = 'Phone Number';
export const SIGN_IN = 'SIGN IN';
export const OTP = 'OTP';
export const VERIFY_OTP = 'Verify OTP';
export const INVALID_OTP_MESSAGE = 'OTP must be 6 characters long';
export const INVALID_NUMBER = 'INVALID_PHONE_NUMBER';
export const SUBSCRIBED = 'Subscribed';
export const NOT_SUBSCRIBED = 'Not Subscribed';
export const NOT_AUTHORIZED_MESSAGE = 'You are not Authorized to view this page kindly click any where to go to login screen.';
