import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.tertiary.main,
    overflow: 'scroll',
    height: '100vh',
    padding: '10px',
  },
  form: {
    paddingTop: theme.spacing(2),
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
  },
  field: {
    '& input': {
      backgroundColor: '#ffffff',
      borderRadius: '3px',
      borderColor: '#1a1717',
      height: '25px',
    },
    '& .MuiFormLabel-root': {
      color: '#1a1717',
      letterSpacing: 0.5,
      fontSize: '15px',
    },
    '& .MuiInputLabel-shrink': {
      fontWeight: 600,
      letterSpacing: 0.9,
    },
    '& .MuiFilledInput-underline': {
      '&::before': { content: 'none' },
      '&::after': { content: 'none' },
    },
    '& .MuiOutlinedInput-root': {
      borderColor: '#26262f',
      '& fieldset': {
        borderColor: '#26262f',
      },
      '&:hover fieldset': {
        borderColor: '#1a1717',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1a1717',
      },
    },
  },
  title: {
    fontWeight: 600,
    letterSpacing: 2,
    lineHeight: 1.1,
    fontSize: 'xx-large',
  },
  forgotPassword: {
    padding: '20px',
    fontFamily: 'Mark OT',
    fontSize: '14px',
  },
  message: {
    fontSize: 16,
  },
  dialog_root: {
    '& .MuiDialog-paper': {
      margin: '32px',
      position: 'relative',
      overflowY: 'auto',
      width: '500px',
    },
  },
}));
